<template>
  <Toast position="bottom-left" />
</template>

<script>
import Toast from "primevue/toast";

/** @deprecated */
export default {
  data() {
    return {
      time: 6000,
    };
  },
  mounted() {
    window.eventBus.on(events.toast.error, (message) => {
      this.add("error", message);
    });

    window.eventBus.on(events.toast.success, (message) => {
      this.add("success", message);
    });
  },
  unmounted() {
    window.eventBus.off(events.toast.success);
    window.eventBus.off(events.toast.error);
  },
  methods: {
    add(severity, message) {
      this.$toast.add({
        severity: severity,
        summary: message,
        life: this.time,
      });
    },
  },
  components: {
    Toast,
  },
};
</script>
