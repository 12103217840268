import Vuex from "vuex";
import userStore from "@/services/user/userStore";
import CategoriesStore from "@/services/deprecated/model/collection/categories/CategoriesStore";

export const store = new Vuex.Store({
  modules: {
    userStore,
    CategoriesStore,
  },
});
