const ArticlesList = () => import("../view/ArticlesList.vue");
const AuthorsList = () => import("../view/AuthorsList.vue");
const ArticleMainInfo = () => import("../view/ArticleMainInfo.vue");
const ArticleBody = () => import("../view/ArticleBody.vue");
const ArticleGallery = () => import("../view/ArticleGallery.vue");
const AuthorDetail = () => import("../view/AuthorDetail.vue");
const SurveysList = () => import("../view/SurveysList.vue");
const SurveyDetail = () => import("../view/SurveyDetail.vue");
const ArticleLayout = () => import("../component/article/ArticleLayout.vue");

const BASE_NAME = "articles-";
const URL = "/clanky";

export default [
  {
    name: BASE_NAME + "list",
    path: URL,
    components: { mainContent: ArticlesList },
    meta: {
      title: "Články",
      layout: "admin",
      right: "articles",
    },
  },

  {
    name: BASE_NAME + "new",
    path: URL + "/novy",
    components: { mainContent: ArticleLayout },
    meta: {
      layout: "admin",
      right: "articles",
    },
    children: [
      {
        name: BASE_NAME + "new-mainInfo",
        path: "zakladni-info",
        components: {
          tab: ArticleMainInfo,
        },
      },
    ],
  },

  {
    name: BASE_NAME + "detail",
    path: URL + "/detail/:id",
    components: { mainContent: ArticleLayout },
    meta: {
      layout: "admin",
      right: "articles",
    },
    children: [
      {
        name: BASE_NAME + "detail-mainInfo",
        path: "zakladni-info",
        components: {
          tab: ArticleMainInfo,
        },
      },
      {
        name: BASE_NAME + "detail-body",
        path: "telo",
        components: {
          tab: ArticleBody,
        },
      },
      {
        name: BASE_NAME + "detail-gallery",
        path: "galerie",
        components: {
          tab: ArticleGallery,
        },
      },
    ],
  },

  {
    name: BASE_NAME + "authors-list",
    path: URL + "/autori",
    components: { mainContent: AuthorsList },
    meta: {
      title: "Autoři",
      layout: "admin",
      right: "articles",
    },
  },
  {
    name: BASE_NAME + "authors-new",
    path: URL + "/autor/novy",
    components: { mainContent: AuthorDetail },
    meta: {
      title: "Nový autor",
      layout: "admin",
      right: "articles",
    },
  },
  {
    name: BASE_NAME + "authors-detail",
    path: URL + "/autor/:id",
    components: { mainContent: AuthorDetail },
    meta: {
      title: "Autor",
      layout: "admin",
      right: "articles",
    },
  },
  {
    name: BASE_NAME + "surveys-list",
    path: URL + "/ankety",
    components: { mainContent: SurveysList },
    meta: {
      title: "Ankety",
      layout: "admin",
      right: "articles",
    },
  },
  {
    name: BASE_NAME + "surveys-new",
    path: URL + "/anketa/nova",
    components: { mainContent: SurveyDetail },
    meta: {
      title: "Nová anketa",
      right: "articles",
      layout: "admin",
    },
  },
  {
    name: BASE_NAME + "surveys-detail",
    path: URL + "/anketa/:id",
    components: { mainContent: SurveyDetail },
    meta: {
      layout: "admin",
      right: "articles",
    },
  },
];
