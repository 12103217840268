import { events } from "@/services/deprecated/events";
import { NETWORK_ERROR } from "./errorCodes";
import { UserService } from "@/services/user/UserService";

/** @deprecated */
function handleError(error) {
  if (error.response) {
    handleErrorResponse(error);
  } else if (error.request) {
    handleRequestError(error);
  } else {
    handleGeneralError(error);
  }
}

function handleErrorResponse(error) {
  Promise.reject(error.response);

  const msg = error.message;

  if (error.response.status == 401) {
    window.eventBus.emit(events.toast.error, "Pro provedení této operace nemáte dostatečná oprávnění.");
    UserService.logout();
  } else {
    window.eventBus.emit(events.toast.error, msg);
    window.eventBus.emit(events.error, error);
  }

  logError(error);
}

function handleRequestError(error) {
  Promise.reject(error.request);

  let msg = error.message;

  if (error.code == NETWORK_ERROR) {
    msg = "Nepodařilo se načíst data. Jste připojení k internetu?";
  }

  window.eventBus.emit(events.toast.error, msg);
  window.eventBus.emit(events.error, error);

  logError(error);
}

function handleGeneralError(error) {
  Promise.reject(error);
  window.eventBus.emit(events.toast.error, error.message);
  window.eventBus.emit(events.error, error);

  logError(error);
}

function logError(error) {
  // @todo log to backend
  console.log(error);
}

export { handleError };
