const Dashboard = () => import("../view/Dashboard.vue");

const NAME_PREFIX = "";

export default [
  {
    name: NAME_PREFIX + "dashboard",
    path: "/",
    components: { mainContent: Dashboard },
    meta: {
      public: false,
      title: "Dashboard",
      layout: "admin",
    },
  },
];
