export default {
  state: {
    byCollectionContent: null,
    bySystemOrder: null,
  },
  mutations: {
    cacheCategories(state, data) {
      if (data.sort == "byCollectionContent") {
        state.byCollectionContent = data.categories;
      } else {
        state.bySystemOrder = data.categories;
      }
    },
  },
  getters: {
    getCategoriesSortedByUser: (state) => {
      return state.byCollectionContent;
    },
    getCategoriesSortedBySystem: (state) => {
      return state.bySystemOrder;
    },
  },
};
