<template>
  <div class="icon">
    <FontAwesomeIcon :icon="iconParams" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faAlignLeft as falAlignLeft, faArrowLeftRotate as falArrowLeftRotate, faArrowsUpDown as falArrowsUpDown, faBars as falBars, faBell as falBell, faBlockBrick as falBlockBrick, faBook as falBook, faBox as falBox, faCalendar as falCalendar, faCamera as falCamera, faCartShopping as falCartShopping, faCircleInfo as falCircleInfo, faClipboardList as falClipboardList, faCoins as falCoins, faEye as falEye, faEnvelope as falEnvelope, faFolder as falFolder, faHeading as falHading, faHashtag as falHashtag, faChampagneGlasses as falChampagneGlasses, faChartLineUp as falChartLineUp, faCheck as falCheck, faChevronLeft as falChevronLeft, faChevronDown as falChevronDown, faChevronRight as falChevronRight, faImage as falImage, faListUl as falListUl, faMagnifyingGlass as falMagnifyingGlass, faMessages as falMessages, faMoneyBillWave as falMoneyBillWave, faNewspaper as falNewspaper, faNoteSticky as falNoteSticky, faPencil as falPencil, faPlus as falPlus, faRectangle as falRectangle, faRightToBracket as falRightToBracket, faRotateForward as falRotateForward, faServer as falServer, faStar as falStar, faStopwatch as falStopwatch, faSquarePollVertical as falSquarePollVertical, faTableList as falTableList, faTrash as falTrash, faUser as falUser, faUserPlus as falUserPlus, faUsers as falUsers, faUserSecret as falUserSecret, faUsersSlash as falUsersSlash, faWatch as falWatch, faWindow as falWindow, faXmark as falXmark } from "@fortawesome/pro-light-svg-icons";

library.add(falAlignLeft, falArrowLeftRotate, falArrowsUpDown, falBars, falBell, falBlockBrick, falBook, falBox, falCalendar, falCamera, falCartShopping, falCircleInfo, falClipboardList, falCoins, falEnvelope, falEye, falFolder, falHading, falHashtag, falChampagneGlasses, falChartLineUp, falCheck, falChevronLeft, falChevronDown, falChevronRight, falImage, falListUl, falMagnifyingGlass, falMessages, falMoneyBillWave, falNewspaper, falNoteSticky, falPencil, falPlus, falRectangle, falRightToBracket, falRotateForward, falServer, falStar, falSquarePollVertical, falStopwatch, falTableList, falTrash, falUser, falUserPlus, falUsers, falUserSecret, falUsersSlash, falWatch, falWindow, falXmark);

/** @deprecated */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    weight: {
      type: String,
      default: "light",
    },
    brand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconParams() {
      if (this.brand) {
        return "fa-brands fa-" + this.name;
      } else {
        return "fa-" + this.weight + " fa-" + this.name;
      }
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100%;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    * {
      transition: fill 0.3s ease;
    }
  }
}
</style>
