<template>
  <div class="gallery" v-if="isOpen" @click.stop="isOpen = false">
    <div class="gallery__close" @click="isOpen = false">
      <icon name="xmark" />
    </div>
    <div class="gallery__arrows gallery__arrows--previous" @click.stop="previous()" v-if="media.length > 1">
      <icon name="chevron-left" />
    </div>
    <div class="gallery__arrows gallery__arrows--next" @click.stop="next()" v-if="media.length > 1">
      <icon name="chevron-right" />
    </div>

    <template v-if="typeof currentMedium == 'string'">
      <div class="gallery__content">
        <img :src="currentMedium" class="gallery__image" />
      </div>
    </template>
    <template v-else>
      <div class="gallery__content" v-html="currentMedium.generateHtml(ImageSizes['1200x900'])" />
    </template>
  </div>
</template>

<script>
import Icon from "@/components/deprecated/icons/Icon.vue";
import { ImageSizes } from "@/enums";

/** @deprecated */
export default {
  data() {
    return {
      currentIndex: 0,
      isOpen: false,
      media: [],
      ImageSizes: ImageSizes,
    };
  },
  computed: {
    currentMedium() {
      return this.media[this.currentIndex];
    },
  },
  mounted() {
    window.eventBus.on(events.gallery.open, this.onOpen);
    window.eventBus.on(events.keypress.arrowLeft, this.previous);
    window.eventBus.on(events.keypress.arrowRight, this.next);
  },
  unmounted() {
    window.eventBus.off(events.gallery.open, this.onOpen);
    window.eventBus.off(events.keypress.arrowLeft, this.previous);
    window.eventBus.off(events.keypress.arrowRight, this.next);
  },
  methods: {
    onOpen(data) {
      this.media = data.media;
      this.currentIndex = data.index ?? 0;
      this.isOpen = true;
    },
    next() {
      if (this.currentIndex + 1 == this.media.length) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    previous() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.media.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    close() {
      this.isOpen = false;
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  --side-size: 70px;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(var(--primary-color-rgb), 0.95) 0%, rgba(#000, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  &__close {
    position: absolute;
    top: 0;
    right: 20px;
    --size: var(--side-size);
    width: var(--size);
    height: var(--size);
    color: #fff;
    padding: calc(var(--size) / 4);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }

  &__content {
    width: calc(100% - 2 * var(--side-size));
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &:deep(img),
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: var(--radius);
      object-fit: contain;
    }
  }
  &__arrows {
    --size: var(--side-size);
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    color: #fff;
    padding: calc(var(--size) / 4);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
    &--previous {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }
}
</style>
