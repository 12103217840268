<template>
  <div :class="{ sidebar: true, open: open }" @mouseenter="open = true" @mouseleave="open = false" @mouseover="open = true">
    <router-link :to="{ name: 'dashboard' }" class="sidebar__logo">
      <img src="/images/logo-c-white.svg" />
    </router-link>

    <div class="sidebar__main-menu-wrapper">
      <admin-layout-main-menu />
    </div>
  </div>
</template>

<script>
import AdminLayoutMainMenu from "./AdminLayoutMainMenu.vue";

export default {
  components: {
    AdminLayoutMainMenu,
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    window.eventBus.on(events.layout.closeSidebar, () => {
      this.open = false;
    });
  },
  unmounted() {
    window.eventBus.off(events.layout.closeSidebar);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: var(--menu-width);
  height: 100vh;
  background: linear-gradient(to bottom, var(--dark-green), var(--light-green));
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  z-index: 500;

  &.open {
    width: var(--menu-width-open);
  }

  &__logo {
    width: var(--menu-width);

    img {
      width: 100%;
      max-width: 32px;
      margin: 20px auto;
      object-fit: contain;
      display: block;
    }
  }

  @include for-size(lg) {
    &__logo {
      margin-bottom: 20px;
    }
  }

  @include for-size(md) {
    &__logo {
      width: 40px;
      margin: 20px 0 15px 20px;
    }
  }
}
</style>
