const AccessDenied = () => import("../view/AccessDenied.vue");
const Error404 = () => import("../view/404.vue");

const NAME_PREFIX = "error-";

export default [
  {
    name: NAME_PREFIX + "accessDenied",
    path: "/pristup-odepren",
    components: { mainContent: AccessDenied },
    meta: {
      public: true,
      title: "Přístup odepřen",
      layout: "error",
    },
  },
  {
    name: NAME_PREFIX + "404",
    path: "/:pathMatch(.*)",
    components: { mainContent: Error404 },
    meta: {
      public: true,
      title: "404 Stránka nenalezena",
      layout: "error",
    },
  },
];
