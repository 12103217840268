const Login = () => import("../view/Login.vue");

const NAME_PREFIX = "public-";

export default [
  {
    name: NAME_PREFIX + "login",
    path: "/login",
    components: { mainContent: Login },
    meta: {
      public: true,
      title: "Přihlášení",
      layout: "public",
    },
  },
];
