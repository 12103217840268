export class User {
  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.image = data.image;
    this.roles = [data.role];
    this.rights = data.rights;
    this.token = data.token;
  }

  hasRole(role) {
    return this.roles.includes(role);
  }

  hasRight(key) {
    return this.rights.includes(key);
  }

  getToken() {
    return this.token;
  }
}
