import { createRouter, createWebHistory } from "vue-router";
import { UserService } from "@/services/user/UserService";
import errorRoutes from "@/module/error/route/errorRoutes";
import publicRoutes from "@/module/public/route/publicRoutes";
import adminRoutes from "@/module/admin/route/adminRoutes";

const routes = [...publicRoutes, ...adminRoutes, ...errorRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);

  const isPrivate = !to.meta.public || !to.meta.public === true;
  const user = await UserService.getUser();
  const requiredRole = to.meta.role;
  const requiredRight = to.meta.right;
  const loginPage = { name: "public-login", query: { redirect: to.fullPath } };
  const unauthorizedPage = { name: "error-accessDenied" };

  if (isPrivate) {
    if (!user) {
      return next(loginPage);
    }

    if (requiredRole && !user.hasRole(requiredRole)) {
      return next(loginPage);
    }

    if (requiredRight && !user.hasRight(requiredRight)) {
      return next(unauthorizedPage);
    }
  }

  next();
});

export { router };
