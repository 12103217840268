<template>
  <div class="topbar">
    <div class="user">
      <div class="user__image">
        <icon name="user" />
      </div>
      <div class="user__email">
        {{ user.email }}
      </div>
    </div>

    <div class="topbar__delimiter" />

    <div class="actions">
      <button class="action" @click.prevent="logout">
        <div class="action__icon">
          <Icon name="right-to-bracket" />
        </div>
        <div class="action__text">Odhlásit se</div>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/deprecated/icons/Icon.vue";
import { UserService } from "@/services/user/UserService";

export default {
  components: { Icon },
  data() {
    return {
      user: this.$store.state.userStore.user,
    };
  },
  methods: {
    logout() {
      UserService.logout();
      window.eventBus.emit(events.toast.success, "Úspěšně ses odhlásil. Těším se příště.");
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  --icon-size: 15px;

  padding: 0 var(--side-padding) 0 calc(var(--menu-width));
  height: 50px;
  display: flex;
  align-items: center;

  &__delimiter {
    width: 1px;
    background: #ddd;
    height: 20px;
    margin: 0 15px;
  }

  .user {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 13px;
    color: #666;
    &__image {
      border-radius: 50%;
      .icon {
        width: var(--icon-size);
        height: var(--icon-size);
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 0;
    font-size: 13px;
    background: transparent;
    color: #666;
    .icon {
      width: var(--icon-size);
      height: var(--icon-size);
    }
    &:hover {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}
</style>
