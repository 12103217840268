export default {
  state: {
    user: null,
  },
  mutations: {
    login(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
  },
};
