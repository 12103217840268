import { User } from "./User";
import { store } from "@/store";

/**
 * Returns currently logged in user or throws error.
 * Do not wait for possible authentication in progress.
 * @throws NoUserException
 */
export function requireUser(): User {
  const user = store.getters.getUser;
  if (!user) {
    throw new NoUserException("User requested. However no user is logged in.");
  }

  return user;
}

export class NoUserException extends Error {}
