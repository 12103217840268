<template>
  <div class="loading">
    <ProgressSpinner />
    <span v-if="text && text.length > 0" class="loading__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

/** @deprecated */
export default {
  props: {
    text: String,
  },
  components: {
    ProgressSpinner,
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__text {
    margin-top: 50px;
  }
}
</style>
