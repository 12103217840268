import { store } from "@/store/store.js";
import { API } from "../deprecated/api/API";
import { API as api } from "../api/API";
import { User } from "./User";
import { events } from "@/services/deprecated/events";
import { AUTHENTICATION_OK, TOKEN_NOT_FOUND } from "./AuthenticationStatuses";
import { addTokenToUrl, catch401 } from "../api";

const BASE_URL = "user/";
const PROFILE_BASE_URL = "profile/";

let authentication = null;
let credentials = null;

const LOCAL_STORAGE_TOKEN_KEY = "token";

function login(token, userData) {
  userData.token = token;
  const user = new User(userData);

  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  store.commit("login", user);
}

function setCredentials(data) {
  credentials = data;
}

async function authenticate() {
  if (authentication) {
    await resetAuthenticationIfNotOk();
  }

  if (authentication === null) {
    authentication = API.post(BASE_URL + "authenticate", credentials, {}, false).then((response) => {
      if (response.status == AUTHENTICATION_OK) {
        login(response.token, response.user);
      } else if (response.status == TOKEN_NOT_FOUND) {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY); // We don't want any message here.
      } else {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        window.eventBus.emit(events.toast.error, response.message);
      }

      return response;
    });
  }

  return authentication;
}

async function getUser() {
  await authentication;
  return store.getters.getUser;
}

export async function logout(invalidateInApi = true) {
  const user = await this.getUser();

  if (user) {
    if (invalidateInApi) {
      const url = addTokenToUrl(PROFILE_BASE_URL + "logout");
      await api.put(url).catch(catch401);
    }
    window.eventBus.emit(events.account.logout);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    store.commit("logout");
    authentication = null;
  }
}

async function resetAuthenticationIfNotOk() {
  return authentication.then((response) => {
    if (response.status !== AUTHENTICATION_OK) {
      authentication = null;
    }
  });
}

function getToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

const UserService = {
  authenticate,
  login,
  logout,
  getUser,
  getToken,
  setCredentials,
};

export { UserService };
