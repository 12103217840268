const NotificationsList = () => import("../view/NotificationsList.vue");
const NotificationDetail = () => import("../view/NotificationDetail.vue");

const BASE_NAME = "notifications-";
const URL = "/notifikace";

export default [
  {
    name: BASE_NAME + "list",
    path: URL,
    components: { mainContent: NotificationsList },
    meta: {
      title: "Notifikace",
      layout: "admin",
      right: "notifications",
    },
  },
  {
    name: BASE_NAME + "new",
    path: URL + "/nova",
    components: { mainContent: NotificationDetail },
    meta: {
      title: "Notifikace",
      layout: "admin",
      right: "notifications",
    },
  },
  {
    name: BASE_NAME + "detail",
    path: URL + "/:id",
    components: { mainContent: NotificationDetail },
    meta: {
      title: "Notifikace",
      layout: "admin",
      right: "notifications",
    },
  },
];
