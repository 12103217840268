<template>
  <div class="public-page">
    <div class="public-page__content">
      <div class="public-page__logo">
        <img src="/images/logo-text-color.svg" />
      </div>

      <router-view name="mainContent" />
    </div>
  </div>
</template>

<script setup lang="ts" />

<style lang="scss">
@import "@/scss/deprecated/public/public.scss";
</style>

<style lang="scss" scoped>
.public-page {
  &__logo {
    display: block;
  }
}
</style>
