/** @deprecated */
const events = {
  toast: {
    success: "success-toast",
    error: "error-toast",
  },
  layout: {
    closeSidebar: "layout-sidebarClose",
  },
  gallery: {
    open: "gallery.open",
  },
  overlay: {
    show: "show-overlay-message",
    hide: "hide-overlay-message",
  },
  account: {
    logout: "logout",
  },
  accounts: {
    reload: "accounts-reload",
  },
  notifications: {
    reload: "notifications-reload",
    allRead: "notifications-all-read",
  },
  parameters: {
    openParameterDialog: "open-parameter-dialog",
    openValueDialog: "open-value-dialog",
    openValueMultipleInsertDialog: "open-value-multiple-insert-dialog",
  },
  dropdown: {
    open: "dropdown.open",
  },
  collection: {
    reloadItem: "collection-reloadItems",
  },
  keypress: {
    arrowRight: "key-arrowRight",
    arrowLeft: "key-arrowLeft",
  },
};

export { events };
