// Basic imports
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";

// Primevue components and styles
import ToastService from "primevue/toastservice";
import "primevue/resources/themes/bootstrap4-light-purple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@/scss/deprecated/customProperties.scss";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";

// Custom events
import mitt from "mitt";
import { events } from "./services/deprecated/events";

window.eventBus = mitt();
window.events = events;

const app = createApp(App);
app.directive("tooltip", Tooltip);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(store);
app.use(ConfirmationService);
app.mount("#app");
