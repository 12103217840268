const Categories = () => import("../view/Categories.vue");
const LegoSets = () => import("../view/LegoSets.vue");
const LegoSetLayout = () => import("../component/legoSet/LegoSetLayout.vue");
const LegoSetMainInfo = () => import("../view/LegoSetMainInfo.vue");
const LegoSetPricesSummaries = () => import("../view/LegoSetPricesSummaries.vue");
const LegoSetSales = () => import("../view/LegoSetSales.vue");

const BASE_NAME = "categories-";
const URL = "/kategorie";

export default [
  {
    name: BASE_NAME + "list",
    path: URL,
    components: { mainContent: Categories },
    meta: {
      title: "Kategorie",
      layout: "admin",
      right: "categories",
    },
  },
  {
    name: BASE_NAME + "legosets",
    path: URL + "/lego-sety",
    components: { mainContent: LegoSets },
    meta: {
      title: "LEGO sety",
      layout: "admin",
      right: "categories",
    },
  },
  {
    name: BASE_NAME + "legosetDetail",
    path: URL + "/lego-set/detail/:id",
    components: { mainContent: LegoSetLayout },
    meta: {
      layout: "admin",
      right: "categories",
    },
    children: [
      {
        name: BASE_NAME + "legosetDetail-mainInfo",
        path: "zakladni-info",
        components: {
          tab: LegoSetMainInfo,
        },
      },
      {
        name: BASE_NAME + "legosetDetail-pricesSummary",
        path: "ceny-souhrny",
        components: {
          tab: LegoSetPricesSummaries,
        },
      },
      {
        name: BASE_NAME + "legosetDetail-sales",
        path: "prodeje",
        components: {
          tab: LegoSetSales,
        },
      },
    ],
  },
];
