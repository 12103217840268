<template>
  <div class="error-layout">
    <div class="error-layout__content">
      <div class="logo-wrapper">
        <img src="/images/logo-text-color.svg" />
      </div>
      <div class="ups">UPS...</div>
      <div class="content-wrapper">
        <router-view name="mainContent" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@import "@/scss/deprecated/error/error.scss";
</style>
